import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// 导入全局样式
import './assets/css/normalize.css'
// 导入element ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 导入js - md5 通过前台js加密的方式对密码等私密信息进行加密的工具
import md5 from "js-md5";
// router-link跳转到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
Vue.prototype.$md5 = md5;
Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
Vue.use(ElementUI)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
