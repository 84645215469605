import Vue from "vue";
import VueRouter from "vue-router";
// const Index = () => import("views/index/index");
const Index = () => import("views/ds/SolutionThree/SolutionThree");
const Login = () => import("views/login/login");
const LowCodePlatform = () => import("views/lowCodePlate/lowCodePlate");
const IOTPlatform = () => import("views/IOTPlatform/IOTPlatform");
const BIPlatform = () => import("views/BIPlatform/BIPlatform");
const AboutUs = () => import("views/AboutUs/AboutUs");
const CustomerQljgj = () => import("views/CustomerQljgj/CustomerQljgj");
const CustomerAOYA = () => import("views/CustomerAOYA/CustomerAOYA");
const SolutionOne = () => import("views/SolutionOne/SolutionOne");
const SolutionTwo = () => import("views/SolutionTwo/SolutionTwo");
const SolutionThree = () => import("views/SolutionThree/SolutionThree");
const CustomerDZMotor = () => import("views/CustomerDZMotor/CustomerDZMotor");
const CustomerJXSponge = () => import("views/CustomerJXSponge/CustomerJXSponge");

const Board = () => import("views/Board/Board");
const SolutionThreeDs = () => import("views/ds/SolutionThree/SolutionThree");
const IOTPlatformDs = () => import("views/ds/IOTPlatform/IOTPlatform");
const LowCodePlatformDs = () => import("views/ds/lowCodePlate/lowCodePlate");
const BIPlatformDs = () => import("views/ds/BIPlatform/BIPlatform");
const ForUs = () => import("views/ds/ForUs/ForUs");
const CustomerJXSpongeDs = () => import("views/ds/CustomerJXSponge/CustomerJXSponge");
const CustomerLH = () => import("views/ds/CustomerLH/CustomerLH");
const CustomerRC = () => import("views/ds/CustomerRC/CustomerRC");
const CustomerHS = () => import("views/ds/CustomerHS/CustomerHS");
const CustomerYL = () => import("views/ds/CustomerYL/CustomerYL");
const CustomerNX = () => import("views/ds/CustomerNX/CustomerNX");
const CustomerBS = () => import("views/ds/CustomerBS/CustomerBS");
const CustomerJS = () => import("views/ds/CustomerJS/CustomerJS");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Index"
  },
  
  {
    name: "Index",
    path: "/Index",
    component: Index
  },
  {
    name: "Login",
    path: "/Login",
    component: Login
  },
  {
    name: "LowCodePlatform",
    path: "/LowCodePlatform",
    component: LowCodePlatform
  }, {
    name: "LowCodePlatformDs",
    path: "/LowCodePlatformDs",
    component: LowCodePlatformDs
  },
  {
    name: "ForUs",
    path: "/ForUs",
    component: ForUs
  },
  {
    name: "IOTPlatform",
    path: "/IOTPlatform",
    component: IOTPlatform
  },
  {
    name: "IOTPlatformDs",
    path: "/IOTPlatformDs",
    component: IOTPlatformDs
  },
  {
    name: "BIPlatform",
    path: "/BIPlatform",
    component: BIPlatform
  },
  {
    name: "BIPlatformDs",
    path: "/BIPlatformDs",
    component: BIPlatformDs
  },
  {
    name: "AboutUs",
    path: "/AboutUs",
    component: AboutUs
  },
  {
    name: "Board",
    path: "/Board",
    component: Board
  },
  {
    name: "SolutionThreeDs",
    path: "/SolutionThreeDs",
    component: SolutionThreeDs
  },
  {
    name: "CustomerQljgj",
    path: "/CustomerQljgj",
    component: CustomerQljgj
  },
  {
    name: "CustomerAOYA",
    path: "/CustomerAOYA",
    component: CustomerAOYA
  },
  {
    name: "SolutionOne",
    path: "/SolutionOne",
    component: SolutionOne
  },
  {
    name: "SolutionTwo",
    path: "/SolutionTwo",
    component: SolutionTwo
  },
  {
    name: "SolutionThree",
    path: "/SolutionThree",
    component: SolutionThree
  },
  {
    name: "CustomerDZMotor",
    path: "/CustomerDZMotor",
    component: CustomerDZMotor
  },
  {
    name: "CustomerJXSponge",
    path: "/CustomerJXSponge",
    component: CustomerJXSponge
  },
  {
    name: "CustomerJXSpongeDs",
    path: "/CustomerJXSpongeDs",
    component: CustomerJXSpongeDs
  },
  {
    name: "CustomerLH",
    path: "/CustomerLH",
    component: CustomerLH
  },
  {
    name: "CustomerRC",
    path: "/CustomerRC",
    component: CustomerRC
  },
  {
    name: "CustomerHS",
    path: "/CustomerHS",
    component: CustomerHS
  },
  {
    name: "CustomerYL",
    path: "/CustomerYL",
    component: CustomerYL
  },
  {
    name: "CustomerNX",
    path: "/CustomerNX",
    component: CustomerNX
  },{
    name: "CustomerJS",
    path: "/CustomerJS",
    component: CustomerJS
  },{
    name: "CustomerBS",
    path: "/CustomerBS",
    component: CustomerBS
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
