<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {}
};
</script>

<style>
html {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color:#7a8e9d ;
} /*正常的未被访问过的链接*/
a:link {
  text-decoration: none ;
  color:#7a8e9d ;
} /*已经访问过的链接*/
a:visited {
  text-decoration: none;
  color:#7a8e9d ;
} /*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
  color:#3d7eff  ;
} /* 正在点击的链接*/
a:active {
  text-decoration: none;
  color:#7a8e9d ;
}
body{
  background-color: #f8f9fb;

}
</style>
